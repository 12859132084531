import React from "react"
import axios from "axios"

import SEO from "../SEO"
import Loader from "../Loader"
import Message from "../Message"
import Heading from "./heading"
import Content from "./content"

import "./page.scss"

const pagesAPI = process.env.API_PAGES

class Page extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            page: {},
            message: undefined,
            isLoading: true
        }
    }

    componentDidMount() {
        const {id} = this.props
        axios.get(`${pagesAPI}/${id}`)
        .then(response => {
            this.setState({
                page: response.data,
                isLoading: false
            })
        })
        .catch(error => {
            this.setState({
                message: {
                    type: "danger",
                    text: error.response.data.message
                },
                isLoading: false
            })
        })
    }

    render() {
        const {isLoading, message, page} = this.state
        if(isLoading === true) {
            return(
                <Loader />
            )
        } else if(message) {
            return(
                <Message type={message.type} message={message.text} />
            )
        } else {
            return(
                <>
                <SEO title={page.title} description={page.description} />
                <Heading title={page.title} />
                <Content content={page.content} />
                </>
            )
        }
    }

}

export default Page